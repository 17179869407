import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import Truncate from 'react-truncate';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import BlogArticle from '../components/Blog';
import FeaturedPost from '../components/FeaturedPost';
import SEO from '../components/SEO';

function Blog({ data, location }) {
  const posts = data.allWordpressPost.edges;
  const latestPost = posts.shift().node;

  return (
    <Layout locale="es" inverted border location={location}>
      <SEO title="Los mejores artículos de temas legales en México" />
      <section id="blog" className="bg-grey mt-4">
        <div className="container">
          <div className="row">
            <FeaturedPost slug={latestPost.slug} featuredMedia={latestPost.featured_media?.source_url} categories={latestPost.categories} title={latestPost.title} excerpt={parse(latestPost.excerpt)} date={latestPost.date} />
          </div>
          <div className="row">
            {data.allWordpressPost.edges.map(({ node }) => (
              <BlogArticle key={node.slug} slug={node.slug} categories={node.categories} title={node.title} excerpt={parse(node.excerpt)} date={node.date} author={node.author.name} authorAvatar={node.author.avatar_urls?.wordpress_96} featuredMedia={node.featured_media?.source_url} description={node.description} />
            ))}

          </div>
        </div>
      </section>
    </Layout>


  );
}

export default Blog;

export const pageQuery = graphql`
query {
  allWordpressPost(filter: {tags: {elemMatch: {name: {eq: "article"}}}}, sort: {order: DESC, fields: date}) {
    edges {
      node {
        date(fromNow: true, locale: "es")
        excerpt
        title
        slug
        featured_media {
          source_url
        }
        categories {
          name
        }
        tags {
          name
        }
        author {
          name
          description
          avatar_urls {
            wordpress_96
          }
        }
      }
    }
  }
}
`;
