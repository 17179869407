import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Truncate from 'react-truncate';
import Blog01 from '../images/blog/blog.jpg';


function FeaturedPost({
  title, slug, excerpt, featuredMedia, categories, date,
}) {
  return (
    <Link to={`/${slug}`} className="blog-link">
      <div className="card featured-card post-card bg-light shadow-sm w-100 mt-3 mb-5">
        <div className="row w-100 d-flex">
          <div className="col-lg-7 col-md-12">
            <img src={featuredMedia} className="img-fluid" />
          </div>
          <div className="col-lg-5 col-md-12 p-4 align-self-center">
            <div className="d-flex mb-2">
              <div className="mr-auto"><small className="badge badge-primary-50 d-inline-block text-muted">{categories[0].name}</small></div>
              <div className="ml-auto"><small className="text-muted">{date}</small></div>
            </div>
            <h2 className="font-weight-bold mb-2">{title}</h2>
            <p className="text-muted text-justify"><Truncate lines={3}>{excerpt}</Truncate></p>
            <p className="text-primary mb-0">Leer más</p>
          </div>
        </div>
      </div>
    </Link>


  );
}

export default FeaturedPost;
