import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Truncate from 'react-truncate';


function BlogArticle({
  title, excerpt, slug, date, author, featuredMedia, categories, authorAvatar,
}) {
  return (

    <div className="col-lg-4 col-md-6 my-3">
      <Link to={`/${slug}`} className="blog-link">
        <div className="card post-card bg-light shadow-sm">
          <div className="card-header">
            <img src={featuredMedia} className="img-fluid" />
          </div>
          <div className="card-body px-3 pt-3 pb-0">
            <div className="d-flex mb-2">
              <div className="mr-auto"><small className="badge badge-primary-50 text-muted">{categories[0].name}</small></div>
              <div className="ml-auto"><small className="text-muted">{date}</small></div>
            </div>
            <h4 className="font-weight-bold mb-3">{title}</h4>
            <p className="text-muted text-justify mb-0">
              <Truncate lines={4}>{excerpt}</Truncate>
            </p>
          </div>
          <div className="card-footer w-100 p-3">
            <div className="d-flex mb-0">
              <div className="mr-auto"><small className="text-muted"><img src={authorAvatar} className="author-post mr-1"/> {author}</small></div>
              <div className="ml-auto"><small className="text-primary">Leer más</small></div>
            </div>
          </div>
        </div>
      </Link>
    </div>


  );
}

export default BlogArticle;
